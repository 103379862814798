import { api } from './api';

export async function ListNews(idNews, highlight, searchTxt, page, limit, token) {
    try {
        let baseURL = `/news?limit=${limit || 10}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idNews) {
            baseURL = `${baseURL}&idNews=${idNews}`
        }
        if (highlight) {
            baseURL = `${baseURL}&highlight=${highlight}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}