import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Sobre nós</h2>
              {props.data ? props.data.paragraph.map((v) => (
                <p key={v} style={{ textAlign: 'justify' }}>{v}</p>
              )) : "loading..."}
              <h3>Por que escolher-nos?</h3>
              {/* <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
              <p style={{ textAlign: 'justify' }}>Poderiamos colocar dezenas de motivos porque você deve escolher a DSS Tracker, mais acreditamos que o que mais importa pra você é a seguinte garantia que faz parte do nosso lema: Recuperação ou pagamento!! É isso mesmo: recuperamos ou pagamos seu veículo!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
