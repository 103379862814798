import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Plans } from "./components/plans";
import { About } from "./components/about";
// import { Services } from "./components/services";
import { News } from "./components/news";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { ListNews } from "./services/news";
import { DetailsNews } from "./components/detailsNews";
// import { Pricing } from "./components/pricing";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [uniqueNotice, setUniqueNotice] = useState(null);
  const [infoNew, setInfoNew] = useState(null);
  const [listNew, setListNew] = useState([]);
  const [listNewHighlight, setListNewHighlight] = useState([]);

  const getListNews = async (idNotice, highlight) => {
      const response = await ListNews(idNotice, highlight, '', 1, 3, null);
      if (response.status === 200) {
        if (idNotice) {
            setInfoNew(response?.result?.list?.length ? response?.result?.list[0] : null);
        } else {
            if (highlight) {
                setListNewHighlight(response?.result?.list || []);
            } else {
                setListNew(response?.result?.list || []);
            }
        }
      }
  };

  useEffect(() => {
    const getID = window.location.href.split('?id=')[1];
    if (getID) {
        setUniqueNotice(getID);
        getListNews(getID, null);
        getListNews(null, false);
        getListNews(null, true);
    } else {
        setUniqueNotice(false);
        setLandingPageData(JsonData);
    }
  }, []);

  const renderPage = () => {
    if (uniqueNotice) {
        return (
            <>
                <Navigation uniqueNotice={uniqueNotice} />
                {infoNew?.id ? <DetailsNews infoNew={infoNew} listNew={listNew} listNewHighlight={listNewHighlight} /> : null}
                <Contact data={landingPageData.Contact} />
            </>
        );
    }
    if (uniqueNotice === false) {
        return (
            <>
                <Navigation />
                <Header data={landingPageData.Header} />
                <Plans data={landingPageData.Plans} />
                <About data={landingPageData.About} />
                {/* <Services data={landingPageData.Services} /> */}
                <News />
                {/* <Testimonials data={landingPageData.Testimonials} /> */}
                {/* <Pricing data={landingPageData.Pricing} /> */}
                {/* <Team data={landingPageData.Team} /> */}
                <Contact data={landingPageData.Contact} />
            </>
        )
    }
    return null;
  };

  return (
    <main style={{ flex: 1, position: 'absolute', top: 0, left: 0, minWidth: '100%' }}>
        {renderPage()}
    </main>
  );
};

export default App;
