import { useState } from "react";
import React from "react";
// import { CreateContact } from "../services/contact";

export const Contact = (props) => {
//   const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleChangeName = (e) => {
    const { value } = e.target;
    setName(value);
  };
  
  const handleChangeEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleChangeMessage = (e) => {
    const { value } = e.target;
    setMessage(value);
  };
  
  const handleSubmit = async (e) => {
    // e.preventDefault();
    // setLoading(true);
    // const response = await CreateContact(name, email, message);
    // setLoading(false);
    // setName('');
    // setEmail('');
    // setMessage('');
    // alert(response?.message);
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8" style={{ paddingTop: 20 }}>
            <div className="row">
              <div className="section-title">
                <h2>ENTRAR EM CONTATO</h2>
                <p>
                    Preencha o formulário abaixo para nos enviar um e-mail e entraremos em contato com você o mais breve possível.
                </p>
              </div>
              <form name="sentMessage">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Seu nome"
                        required
                        value={name}
                        onChange={handleChangeName}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        value={email}
                        className="form-control"
                        placeholder="Seu e-mail"
                        required
                        onChange={handleChangeEmail}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    value={message}
                    className="form-control"
                    rows="4"
                    placeholder="Mensagem..."
                    required
                    onChange={handleChangeMessage}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                    // disabled={loading}
                    onClick={handleSubmit}
                    className="btn btn-custom btn-lg"
                >
                    Enviar mensagem
                    {/* {loading ? 'Carregando...' : 'Enviar mensagem'} */}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de contato</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> CNPJ
                </span>
                {props.data ? props.data.cnpj : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Celular
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <span>
                <i className="fa fa-map-marker"></i> Endereço
              </span>{" "}
              <p>
                {props.data ? props.data.address_title : "loading"}
              </p>
              <p>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <div
                      onClick={() => {
                        if (props.data.instagram) {
                          window.open(props.data.instagram);
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fa fa-instagram"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Escritório administrativo | &copy; 2024 DSS TRACKER
          </p>
        </div>
      </div>
    </div>
  );
};
