/* eslint-disable react/jsx-no-target-blank */
import { lightFormat } from "date-fns";
import React from "react";

export const Image = ({ id, title, author, criated, largeImage, smallImage }) => {
  return (
    <div className="noticias-item">
      <div className="hover-bg">
        {" "}
        <a href={`${window.origin}?id=${id}`} title={title} data-lightbox-gallery="gallery1">
          <div className="hover-text" style={{ padding: "90px 0px 0px 0px" }}>
            <h4>{title}</h4>
            <h5>{`${author}, ${lightFormat(criated, 'dd/MM/yyyy HH:mm')}`}</h5>
          </div>
          <img src={smallImage} style={{ minHeight: 200 }} className="img-responsive" alt={title} />{" "}
        </a>{" "}
      </div>
    </div>
  );
};
