import { lightFormat } from "date-fns";
import React from "react";

export const DetailsNews = ({ infoNew, listNew, listNewHighlight }) => {
  return (
    <div id="noticias" className="text-center">
        <div className="noticias-container">
            <div className="noticias-info">
                <img
                    src={infoNew?.cover}
                    className="noticias-img"
                    alt="noticias-img"
                />
                <h2>{infoNew?.title}</h2>
                <text>{`Por ${infoNew?.author}${infoNew?.created_at ? `, em ${lightFormat(new Date(infoNew?.created_at), 'dd/MM/yyyy HH:mm')}` : ''}`}</text>
                <text style={{ marginTop: 40 }} dangerouslySetInnerHTML={{ __html: infoNew?.description }} />
            </div>
            <div className="last-notice">
                <h3>Últimas notícias</h3>
                {listNew.map((v) => (
                    <div key={v.id} className="last-notice-item" onClick={() => window.location.replace(`${window.origin}/?id=${v.id}`)}>
                        <img src={v.cover} alt="" className="last-notice-item-img" />
                        <div className="last-notice-item-info">
                            <h4>{v?.title}</h4>
                            <text style={{ color: '#333' }}>{infoNew?.created_at ? `${lightFormat(new Date(infoNew?.created_at), 'dd/MM/yyyy HH:mm')}` : ''}</text>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="last-notice" style={{ marginTop: 40, width: '100%', padding: '0px 40px 0px 40px' }}>
            <h2>Você pode gostar também</h2>
            <div className="last-notice-container-main">
                {listNewHighlight.map((v) => (
                    <div key={v.id} className="last-notice-item-main" onClick={() => window.location.replace(`${window.origin}/?id=${v.id}`)}>
                        <img src={v.cover} alt="" className="last-notice-item-img" style={{ width: '100%' }} />
                        <div className="last-notice-item-info-main">
                            <h4>{v?.title}</h4>
                            <text>{infoNew?.created_at ? `${lightFormat(new Date(infoNew?.created_at), 'dd/MM/yyyy HH:mm')}` : ''}</text>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
};
