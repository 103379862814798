import { ListNews } from "../services/news";
import { Image } from "./image";
import React, { useEffect, useState } from "react";

export const News = () => {
    const [listNews, setListNews] = useState([]);

    useEffect(() => {
        getListNews();
    }, []);

    const getListNews = async () => {
        const response = await ListNews(null, true, '', 1, 5, null);
        if (response.status === 200) {
            setListNews(response?.result?.list || []);
        }
    };
  return (
    <div id="noticias" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={{ textAlign: 'center' }}>Notícias</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="row">
          <div className="noticias-items">
            {listNews?.length
              ? listNews.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                        id={d.id}
                      title={d.title}
                      author={d.author}
                      criated={d.created_at}
                      largeImage={d.cover}
                      smallImage={d.cover}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
