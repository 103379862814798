/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" style={{ display: 'flex', alignItems: 'center' }}>
            <img src="img/logo-header.png" height="70px" width="70px" style={{ objectFit: 'contain' }} />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {props.uniqueNotice ? (
                <li>
                    <a href={window.origin} className="page-scroll">
                        Ir para página principal
                    </a>
                </li>
            ) : (
                <>
                    <li>
                        <a href="#plans" className="page-scroll">
                            Planos
                        </a>
                    </li>
                    <li>
                        <a href="#about" className="page-scroll">
                            Sobre nós
                        </a>
                    </li>
                    {/* <li>
                    <a href="#blog" className="page-scroll">
                        Blog
                    </a>
                    </li> */}
                    <li>
                        <a href="#noticias" className="page-scroll">
                            Notícias
                        </a>
                    </li>
                    {/* <li>
                    <a href="#portfolio" className="page-scroll">
                        Galeria
                    </a>
                    </li>
                    <li>
                    <a href="#testimonials" className="page-scroll">
                        Feedbacks
                    </a>
                    </li>
                    <li>
                    <a href="#pricing" className="page-scroll">
                        Benefícios
                    </a>
                    </li> */}
                    {/* <li>
                    <a href="#team" className="page-scroll">
                        Team
                    </a>
                    </li> */}
                    <li>
                        <a href="#contact" className="page-scroll">
                            Contato
                        </a>
                    </li>
                </>
            )}
            <li>
                <a style={{ color: 'white', backgroundColor: '#031D40', borderRadius: 25, paddingRight: 20, paddingLeft: 20 }} href="https://plataforma.dsstracker.com.br/" target="_blank" className="page-scroll" rel="noreferrer">
                    ÁREA DO CLIENTE
                </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
