/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { ListPlans } from "../services/plans";
import { toPrice } from "../utils/masks";

export const Plans = (props) => {
    const style = {
        fontSize: '38px',
        marginBottom: '20px',
        transition: 'all 0.5s',
        color: '#fff',
        width: 100,
        height: 100,
        // padding: '10px 0px',
        borderRadius: '50%',
        backgroundColor: '#031D40',
        // background: 'linear-gradient(to right, #00427C 0%, #031D40 100%)',
        boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.05)',
    };
    const styleIcon = {
        height: 100,
        width: 100,
        borderRadius: 50,
    };
    
    const [listPlan, setListPlan] = useState([]);

    useEffect(() => {
        getListPlans();
    }, []);

    const getListPlans = async () => {
        const response = await ListPlans(null, '', 1, null, null);
        if (response.status === 200) {
            setListPlan(response?.result?.list || []);
        }
    };

    return (
        <div id="plans" className="text-center">
            <div className="container">
                <div style={{ marginTop: 80 }} className="col-md-10 col-md-offset-1 section-title">
                    <h2>Planos</h2>
                </div>
                <div className="row" style={{ padding: 10, minWidth: '100%', flexWrap: 'wrap', justifyContent: 'center', display: 'flex', paddingBottom: 40 }}>
                    {listPlan.length
                        ? listPlan.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="row-item">
                                {d.name !== 'Plano Recuperação Garantida' ? null : <div className="mais-popular" style={{ position: 'absolute', top: '-20px', backgroundColor: '#031D40', padding: '7px 10px', borderRadius: '5px', color: '#fff', fontWeight: 'bold' }}>Mais Popular</div>}
                                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <div style={style}>
                                        <img src={d.photo} style={styleIcon} />
                                    </div>
                                    <h3>{d.name}</h3>
                                    <p>{d.description}</p>
                                </div>
                                <h2>{`R$ ${toPrice(d.value, 2)}`}</h2>
                                <div
                                    style={{
                                        backgroundColor: '#031D40',
                                        backgroundImage: 'linear-gradient(to right, #031D40 0%, #000A1A 100%)',
                                        borderRadius: 25,
                                        color: '#ffffff',
                                        transition: 'all 0.5s linear',
                                        letterSpacing: 1,
                                        padding: '14px 34px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        if (d.type === 'aceite_digital') {
                                            window.open(`https://plataforma.dsstracker.com.br?aceite_digital=${d.id}`);
                                        } else {
                                            window.open(`https://wa.me/558521808296?text=Olá, gostaria de contratar o ${d.name}.`);
                                        }
                                    }}
                                >
                                    <text>{d.type === 'aceite_digital' ? 'Contratar agora' : 'QUERO receber uma proposta'}</text>
                                </div>
                            </div>
                        ))
                        : "Loading..."}
                </div>
            </div>
        </div>
    );
};
